<template>
  <div>
    <p v-if="!titshow" class="title"><el-link type="primary" :underline="false" @click="homepage(1)"
        class="head">商品列表</el-link></p>
    <p class="title" v-if="titshow"><el-link type="primary" :underline="false" @click="homepage(1)"
        class="head">商品列表</el-link> >>
      <el-link type="primary" :underline="false" class="head" @click="homepage(2)">{{ this.$route.params.b }}</el-link> >>
      <span>{{ this.$route.params.a }}</span>
    </p>
    <div class="agent_search">
      <div style="padding-top:10px">
        <label class="lab" for="searchname">商品名称</label>
        <el-input clearable name="searchname" placeholder="请输入商品名称" class="goods_search" v-model="goodsname"></el-input>
        <label class="lab" for="searchnum">商品编号</label>
        <el-input clearable name="searchnum" placeholder="请输入商品编号" class="goods_search" v-model="goodsnum"></el-input>
        <label class="lab" for="select">商品状态</label> <el-select @change="getlist(1)" v-model="goods_state" clearable
          placeholder="请选择" name="select">
          <el-option v-for="item in state_op" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <Button type="primary" style="margin:0 5px 0 5px" @click="getlist(1)">搜索</Button>
        <Button type="primary" @click="reset">重置</Button>
      </div>
    </div>
    <p style="textAlign:left;margin:120px 0 10px 20px">
      <Button type="primary" @click="morepublish">批量发布</Button>
      <Button type="primary" style="float: right;margin-right: 20px;" @click="timing = !timing">定时改价</Button>
      <Button type="primary" @click="replace_price" style="float: right;margin-right: 20px;">更新邀购电子进价</Button>
      <!-- 昊天平台上架更新功能 -->
    </p>
    <div>
      <el-table :data="tableData" border style="width: 99%;marginLeft:10px" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55">
        </el-table-column>
        <el-table-column prop="good_coding" label="商品编码" width="80">
        </el-table-column>
        <el-table-column prop="good_name" label="商品名称" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="商品状态" width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.is_release == 1">已发布</span>
            <span v-if="scope.row.is_release == 2">未发布</span>
            <span v-if="scope.row.is_release == 3">维护中</span>
          </template>
        </el-table-column>
        <el-table-column prop="purchase_price" label="进价" width="110">
          <template slot-scope="scope">
            <el-input v-model="scope.row.purchase_price" style="width:80px" @blur="purchase_price(scope.row)"
              @focus="oldprice = scope.row.purchase_price"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="商品销售价" width="140">
          <template slot-scope="scope">
            <el-input v-model="scope.row.price" style="width:100px" @blur="pricechange(scope.row)"
              @focus="oldprice = scope.row.price"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="official_price" label="官方价" width="110">
          <template slot-scope="scope">
            <el-input v-model="scope.row.official_price" style="width:80px" @blur="official_price(scope.row)"
              @focus="oldprice = scope.row.official_price"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="supplier_str" label="商品归属" width="110">
        </el-table-column>
        <el-table-column label="定时改价时间和价格" align="center" width="210" show-overflow-tooltip>
          <template slot-scope="scope" v-if="scope.row.change_time">
            <span style="color:blue">{{ scope.row.change_time }}</span>,<span style="color:red">{{ scope.row.change_price
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-dropdown trigger="click" placement="bottom" >
              <span class="el-dropdown-link">
                操作<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown" style="width: 147px !important;">
                <el-dropdown-item @click.native="edit(scope.row)" style="text-align:center">编辑</el-dropdown-item>
                <el-dropdown-item style="text-align:center" v-if="scope.row.supplier_id == 168"
                  @click.native="comSumit(scope.row)">组合商品</el-dropdown-item>
                <el-dropdown-item style="text-align:center" v-if="scope.row.is_release == 2"
                  @click.native="todrop_up(scope.row)">下架</el-dropdown-item>
                <el-dropdown-item style="text-align:center" v-if="scope.row.is_release == 1"
                  @click.native="defend(scope.row)">维护</el-dropdown-item>
                <el-dropdown-item style="text-align:center" v-if="scope.row.is_release == 3"
                  @click.native="undefend(scope.row)">取消维护</el-dropdown-item>
                <el-dropdown-item style="text-align:center" v-if="scope.row.is_release == 2"
                  @click.native='publish(scope.row)'>发布</el-dropdown-item>
                <el-dropdown-item style="text-align:center" v-if="scope.row.is_release == 1"
                  @click.native='unpublish(scope.row)'>取消发布</el-dropdown-item>
                <!-- <el-dropdown-item style="text-align:center" @click.native="setautoissue(scope.row)">定时上架</el-dropdown-item>
                  <el-dropdown-item style="text-align:center" @click.native="toupdate(scope.row)">定时改价</el-dropdown-item> -->
                <el-dropdown-item style="text-align:center" v-if="scope.row.detect_phone == 2"
                  @click.native="check_phone(scope.row, 1)">开启检测手机号</el-dropdown-item>
                <el-dropdown-item style="text-align:center" v-if="scope.row.detect_phone == 1"
                  @click.native="check_phone(scope.row, 2)">关闭检测手机号</el-dropdown-item>
                <el-dropdown-item style="text-align:center" @click.native="pingbiset(scope.row)">屏蔽字符设置</el-dropdown-item>
                <el-dropdown-item style="text-align:center"
                  @click.native="tencentset(scope.row)">腾讯5-11位检测</el-dropdown-item>
                <el-dropdown-item style="text-align:center"
                  @click.native="tencentset10(scope.row)">绿钻5-10位检测</el-dropdown-item>
                <el-dropdown-item style="text-align:center"
                  @click.native="allow_loss(scope.row)">允许亏损设置</el-dropdown-item>
       
              </el-dropdown-menu>
            </el-dropdown>
            <el-button type="text" v-if="timing" style="padding-left: 10px;" @click="toupdate(scope.row)">定时改价</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination style="margin-top:5px;textAlign:center" background prev-text='上一页' next-text='下一页'
        layout="total , prev, pager, next, jumper" @current-change="current_change" :total="total" :page-size="pagesize"
        :current-page.sync="currentPage">
      </el-pagination>
    </div>
    <!-- 定时上架 -->
    <el-dialog :visible.sync="setissuedialog" width="800px">
      <span slot="title" style="font-size:16px;text-align:left;">
        设置商品定时上架
      </span>
      <p style="height:60px;margin:0;text-align:left;">
        <label style="display:inline-block;width:100px;text-align: right;">商品名称：</label><span name="name">{{
          row_value.good_name }}</span>
      </p>
      <p style="height:60px;margin:0;text-align:left;" class="timelist">
        <label style="display:inline-block;width:100px;text-align: right;">定时周期：</label>
        <el-checkbox v-model="monday" label="每周一" border size="small"></el-checkbox>
        <el-checkbox v-model="tuesday" label="每周二" border size="small"></el-checkbox>
        <el-checkbox v-model="wednesday" label="每周三" border size="small"></el-checkbox>
        <el-checkbox v-model="thursday" label="每周四" border size="small"></el-checkbox>
        <el-checkbox v-model="friday" label="每周五" border size="small"></el-checkbox>
        <el-checkbox v-model="saturday" label="每周六" border size="small"></el-checkbox>
        <el-checkbox v-model="sunday" label="每周日" border size="small"></el-checkbox>
      </p>
      <p style="height:60px;margin:0;text-align:left;">
        <label style="display:inline-block;width:100px;text-align: right;">定时上架时间：</label>
        <el-time-picker is-range v-model="issuetime" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间"
          placeholder="选择时间范围" @change="sjtimes" value-format="HH:mm:ss" :disabled="sjtime">
        </el-time-picker>
      </p>
      <p style="height:60px;margin:0;text-align:left;">
        <label style="display:inline-block;width:100px;text-align: right;">定时维护时间：</label>
        <el-time-picker is-range v-model="issuetimes" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间"
          placeholder="选择时间范围" value-format="HH:mm:ss" :disabled="whtime" @change="whtimes()">
        </el-time-picker>
      </p>
      <p style="height:60px;margin:0;text-align:left;">
        <label style="display:inline-block;width:100px;text-align: right;">定时开关：</label>
        <el-switch v-model="issuetimeswitch" active-color="#13ce66" inactive-color="#ff4949" :active-value=1
          :inactive-value=2>
        </el-switch>
      </p>
      <el-button type="success" @click="setissuetime()">提交</el-button>
    </el-dialog>
    <!-- 定时改价 -->
    <el-dialog :visible.sync="updatedialog" width="500px">
      <span slot="title" style="font-size:16px">
        商品名称：{{ info_name }}
      </span>
      <p style="text-align:center;height:50px;margin:0">
        <label style="display:inline-block;width:130px;text-align: right;">进价：</label><el-input name="code"
          v-model="row_info.purchase_price" placeholder="请输入商品进价" type="number" style="width:210px"></el-input>
      </p>
      <!-- <p style="text-align:center;height:50px;margin:0">
              <label style="display:inline-block;width:130px;text-align: right;">销售价：</label><el-input v-model="row_info.price" placeholder="请输入商品销售价" type="number" min="0" style="width:210px"></el-input>
            </p> -->
      <!-- <p style="text-align:center;height:50px;margin:0">
              <label style="display:inline-block;width:130px;text-align: right;">价格上限：</label><el-input v-model="row_info.price_cap" placeholder="请输入商品价格上限" type="number" min="0" style="width:210px"></el-input>
            </p> -->
      <!-- <p style="text-align:center;height:50px;margin:0">
              <label style="display:inline-block;width:130px;text-align: right;">官方价格：</label><el-input v-model="row_info.official_price" placeholder="请输入商品价格上限" type="number" min="0" style="width:210px"></el-input>
            </p> -->
      <p style="text-align:center;height:50px;margin:0">
        <label style="display:inline-block;width:130px;text-align: right;">更新时间：</label>
        <el-date-picker v-model="row_info.change_time" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="选择日期时间">
        </el-date-picker>
      </p>
      <el-button type="success" @click="update_price()">提交</el-button>
    </el-dialog>
    <el-dialog title="操作提示" :visible.sync="testqqshow" width="500px">
      <span v-if='teststate == 1'>您确定要开启账号检测吗？</span>
      <span v-if='teststate == 2'>您确定要关闭账号检测吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="testqqshow = false">取 消</el-button>
        <el-button type="primary" @click="check_phone()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="操作提示" :visible.sync="pingbishow" width="500px">
      <span>是否屏蔽中、英文特殊符号？</span>
      <p>
        <el-radio v-model="pingbistate" :label=1>开启</el-radio>
        <el-radio v-model="pingbistate" :label=2>关闭</el-radio>
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="pingbishow = false">取 消</el-button>
        <el-button type="primary" @click="pingbiset()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="操作提示" :visible.sync="tencentshow" width="500px">
      <span>是否开启腾讯账号5-11位检测？</span>
      <p>
        <el-radio v-model="tencentstate" :label=1>开启</el-radio>
        <el-radio v-model="tencentstate" :label=2>关闭</el-radio>
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="tencentshow = false">取 消</el-button>
        <el-button type="primary" @click="tencentset()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="操作提示" :visible.sync="tencent10show" width="500px">
      <span>是否开启QQ绿钻5-10位检测？</span>
      <p>
        <el-radio v-model="tencentstate10" :label=1>开启</el-radio>
        <el-radio v-model="tencentstate10" :label=2>关闭</el-radio>
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="tencent10show = false">取 消</el-button>
        <el-button type="primary" @click="tencentset10()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="操作提示" :visible.sync="lossset" width="500px">
      <span style="font-size: 16px;">是否对{{ row_value.good_name }}进行允许亏损</span>
      <p style="margin-top: 10px;">
        亏损开关：<el-switch v-model="loss_state" active-color="#13ce66" inactive-color="#ff4949" :active-value="1"
          :inactive-value="2">
        </el-switch>
        <span v-if="loss_state == 1" style="color:green;padding-left: 10px;">已打开亏损</span>
        <span v-else style="color:red;padding-left: 10px;">已关闭亏损</span>
      </p>
      <p style="margin:0 auto;margin-top: 30px;">
        <el-button @click="lossset = false">取 消</el-button>
        <el-button type="primary" @click="allow_loss()">确 定</el-button>
      </p>
    </el-dialog>


    <el-dialog :visible.sync="comShow" width="1000px">
      <span slot="title">
        <span style="font-size: 16px;">组合商品&nbsp;&nbsp;商品ID:{{ row_value.id }}</span>
      </span>
      <div class="agent_search" style="height:60px;border: none;text-align:left;">
        <label class="lab" for="searchname">关键词</label>
        <el-input clearable placeholder="商品名称/商品编码" class="goods_search" v-model="comkeywords"></el-input>
        <label class="lab" for="selectstate">是否组合</label>
        <el-select style="width:150px" v-model="comgoodsstatus" clearable placeholder="请选择" @change="releveCom">
          <el-option v-for="item in options4" :key="item.value" :label="item.label" :value="item.value">
          </el-option></el-select>
        <!-- <span @click="comSousuo()">搜索</span><span @click="clearCom()">重置</span> -->
        <section style="margin-left: 10px;display: inline-block;">
          <el-button type="primary" @click="comSousuo()" size="medium">搜索</el-button>
          <el-button type="primary" @click="clearCom()" size="medium">重置</el-button>
        </section>


      </div>
      <el-table ref="multipleTable" :data="comsitList" tooltip-effect="dark" style="width: 100%" highlight-current-row
        height='480'>
        <el-table-column align="center" label="商品名称" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.good_name }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="coding" label="商品编码" width="120" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="purchase_price" label="进价" width="130" show-overflow-tooltip>
        </el-table-column>
        <!-- <el-table-column align="center" prop="max_price" label="价格上限" width="130" show-overflow-tooltip>
        </el-table-column> -->
        <el-table-column align="center" label="商品状态" width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.is_release == 3" style="color: red;">维护中</span>
            <span v-else-if="scope.row.is_release == 2">未发布</span>
            <span v-else-if="scope.row.is_release == 1">已发布</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="商品归属" width="140">
          <template slot-scope="scope">
            {{ scope.row.s_name }}
          </template>
        </el-table-column>
        <!-- <el-table-column align="center" label="商品权限" width="160" show-overflow-tooltip>
          <template slot-scope="scope">
            <span style="cursor:pointer;color:blue;padding-right:10px" @click="getupdateinfo(scope.row)"
              v-if='scope.row.fixedPriceChange'>详情</span>
            {{ scope.row.is_agent_show == '1' ? '诚美和商户平台 ' : scope.row.is_agent_show == '2' ? ' 诚美' : ' ' }}
          </template>
        </el-table-column> -->

        <el-table-column width="130" align="left">
          <template slot-scope="scope">
            <el-button @click="comsitBlo(scope.row, 1, scope.$index)" type="primary" size="small"
              v-if="scope.row.is_relation == 2" style="width:80px;">组合</el-button>
            <el-button type="info" @click="comsitBlo(scope.row, 2, scope.$index)" size="small"
              v-else-if="scope.row.is_relation == 1" style="width:80px;">已组合</el-button>
          </template>
        </el-table-column>
        <!-- <el-table-column label="操作" width="160" show-overflow-tooltip v-if="goodsstatus == 1">
          <template slot-scope="scope">
            <el-button @click="topLink(scope.row)" type="primary" style="width:80px;"> <span> {{ scope.row.sort == 0 ?
              '置顶' : '取消置顶' }} </span> </el-button>
          </template>
        </el-table-column> -->
      </el-table>
      <el-pagination class="fy" layout="total , prev, pager, next, jumper" prev-text='上一页' next-text='下一页'
        @current-change="com_current_change" :total="com_total" :page-size="pagesizes"
        :current-page.sync="com_currentPage" background>
      </el-pagination>
    </el-dialog>



  </div>
</template>
<script>
export default {
  data() {
    return {
      lossset: false,
      loss_state: 2,
      oldprice: "",
      timing: false,
      titshow: false,
      currentPage: 1,
      pagesize: 10,
      total: 0,
      tencentstate10: 2,
      tencent10show: false,
      tencentstate: 2,
      tencentshow: false,
      pingbistate: 2,
      pingbishow: false,
      teststate: "",
      testqqshow: false,
      updatedialog: false,
      info_name: "",
      row_info: {
        gid: "",
        price: "",
        purchase_price: "",
        official_price: "",
        change_time: ""
      },
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
      issuetimeswitch: 2,
      whtime: false,
      sjtime: false,
      timetype: 2,
      issuetimes: [],
      issuetime: [],
      issue_check: [],
      row_value: {},
      setissuedialog: false,
      checked: [],
      tableData: [],
      state_op: [{
        value: '1',
        label: '已发布'
      }, {
        value: '2',
        label: '未发布'
      }
        , {
        value: '3',
        label: '维护中'
      }],
      goodsname: "",
      goodsnum: "",
      goods_state: "1",
      a: "",
      b: "",
      c: "",
      comShow: false,
      comsitList: [],
      com_total: 0,
      com_currentPage: 1,
      pagesizes: 10,
      comkeywords: '',
      comgoodsstatus: '',
      options4: [{
        value: 1,
        label: '已组合'
      }, {
        value: 2,
        label: '未组合'
      }],
    }
  },
  mounted() {
    if (this.$route.params.ref != 2) {
      this.$router.push("/homes/goods_order")
      return false
    }
    if (this.$route.params.id) {
      this.goodsname = ''
      this.titshow = true
    } else {
      this.titshow = false
      this.goodsname = this.$route.params.g_name
    }
    this.getlist()
  },
  methods: {


    comSumit(row) {
      this.row_value = row
      this.comrechargelist()
      this.comShow = true
    },

    comrechargelist() {
      let params = {
        gid: this.row_value.id,
        page: this.com_currentPage,
        limit: this.pagesizes,
        type:this.comgoodsstatus,
        good_name:this.comkeywords

      }
      console.log(params);

      this.$axios.post('supplier/good/relation.child.list', params)
        .then(res => {
          console.log(res.data, "sffffff");
          if (res.data.code == 20000) {
            this.comsitList = res.data.data.rows
            this.com_total = res.data.data.total
          } else {
            this.$message({
              type: "warning",
              message: res.data.message
            })
            this.comrechargelist()
          }
        })

    },

    releveCom() {
      this.com_currentPage=1
      this.comrechargelist()
    },

    //组合
    comsitBlo(row, op, index) {
      // this.comsitList[index].is_relation = op
      let params = { 'gid': this.row_value.id, 'child_id': row.id, type: op, merchant_number: this.merchant }

      this.$axios.post('supplier/good/relation.child', params)
        .then(res => {
          if (res.data.code == 20000) {
            console.log(op, "oooooooooooppppppppppp");

            this.$message({
              type: 'success',
              message: op == 1 ? '组合成功' : '已取消组合'
            })
            this.comrechargelist()
          } else {
            this.$message({
              type: "warning",
              message: res.data.message
            })
          }
        })

    },

    //搜索
    comSousuo(val, index) {
      this.com_currentPage = 1
      this.comrechargelist()
    },
    //重置
    clearCom(val, index) {

      this.comkeywords = ""
      this.comgoodsstatus = ""
      this.com_currentPage = 1
      this.comrechargelist()
    },

    com_current_change(currentPage) {
      // this.com_currentPage = currentPage;
      this.com_currentPage = currentPage
      this.comrechargelist()
    },


    //允许亏损
    allow_loss(row) {
      this.lossset = !this.lossset
      if (row != undefined) {
        this.row_value = row;
        this.loss_state = row.sell_at_a_loss
      }
      if (this.lossset == false) {
        this.$axios.post('supplier/good/set.sell_at_a_loss', { gid: this.row_value.id, sell_at_a_loss: this.loss_state })
          .then(res => {
            if (res.data.code == 20000) {
              this.$message({
                type: "success",
                message: res.data.message
              })
              this.getlist()
            } else {
              this.$message({
                type: "warning",
                message: res.data.message
              })
              this.getlist()
            }
          })
      }
    },
    //进价
    purchase_price(row) {
      let reg = /^([0-9][0-9]*)+(\.[0-9]{1,4})?$/
      if (!reg.test(row.price)) {
        this.$message({
          type: "warning",
          message: "请输入正确的商品进价"
        })
        this.getlist()
      } else {
        if (parseFloat(row.purchase_price) == parseFloat(this.oldprice)) {
          this.getlist()
          return
        }
        this.$axios.post('/supplier/good/change.purchase.price', { gid: row.id, purchase_price: row.purchase_price }).then(res => {
          if (res.data.code == 20000) {
            this.$message({
              type: "success",
              message: res.data.message
            })
            this.getlist()
          } else {
            this.$message({
              type: "warning",
              message: res.data.message
            })
            this.getlist()
          }
        })
      }
    },
    //昊天平台上架更新功能
    replace_price() {
      this.$axios.post('/supplier/merchant/update.yaogou.price').then(res => {
        if (res.data.code == 20000) {
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          this.getlist()
        }
      })
    },
    //官方价
    official_price(row) {
      let reg = /^([0-9][0-9]*)+(\.[0-9]{1,4})?$/
      if (!reg.test(row.price)) {
        this.$message({
          type: "warning",
          message: "请输入正确的商品官方价"
        })
        this.getlist()
      } else {
        if (parseFloat(row.official_price) == parseFloat(this.oldprice)) {
          this.getlist()
          return
        }
        this.$axios.post('/supplier/good/change.official.price', { gid: row.id, official_price: row.official_price }).then(res => {
          if (res.data.code == 20000) {
            this.$message({
              type: "success",
              message: res.data.message
            })
            this.getlist()
          } else {
            this.$message({
              type: "warning",
              message: res.data.message
            })
            this.getlist()
          }
        })
      }
    },
    pricechange(row) {
      let reg = /^([0-9][0-9]*)+(\.[0-9]{1,4})?$/
      if (!reg.test(row.price)) {
        this.$message({
          type: "warning",
          message: "请输入正确的商品销售价"
        })
        this.getlist()
      } else {
        if (parseFloat(row.price) == parseFloat(this.oldprice)) {
          this.getlist()
          return
        }
        this.$axios.post('/supplier/good/change.price', { gid: row.id, price: row.price }).then(res => {
          if (res.data.code == 20000) {
            this.$message({
              type: "success",
              message: res.data.message
            })
            this.getlist()
          } else {
            this.$message({
              type: "warning",
              message: res.data.message
            })
            this.getlist()
          }
        })
      }
    },
    //批量发布
    morepublish() {
      if (this.checked.length == 0) {
        this.$message({
          type: 'warning',
          message: "请先勾选订单"
        })
        return false
      }
      var chec = [];
      for (let i = 0; i < this.checked.length; i++) {
        if (this.checked[i].is_release == 2) {
          chec.push(this.checked[i].id)
        }
      }
      chec = chec.join(",")
      if (chec == '') {
        this.$message({
          type: "warning",
          message: '已发布、发布中不可批量发布'
        })
        return false
      }
      this.$axios.post("/supplier/good/release", { gids: chec, type: 1 }).then(res => {
        if (res.data.code == 20000) {
          this.$message({
            type: "success",
            message: res.data.message
          })
          this.getlist(1)
        } else {
          this.$message({
            type: "warning",
            message: res.data.message
          })
        }
      })
    },
    current_change(page) {
      this.currentPage = page
      this.getlist()
    },
    //绿钻检测
    tencentset10(row) {
      this.tencent10show = !this.tencent10show;
      if (row != undefined) {
        this.row_value = row;
        this.tencentstate10 = this.row_value.detect_qq_lv
      }
      if (this.tencent10show == false) {
        this.$axios.post('/supplier/good/list/switch.operate', { gid: this.row_value.id, switch_type: 4, switch: this.tencentstate10 })
          .then((res) => {
            if (res.data.code == 20000) {
              this.$message({
                message: '设置成功!',
                type: 'success',
                center: 'true',
              });
              this.getlist(1)
            } else {
              this.$message({
                message: '设置失败!',
                type: 'warning',
                center: 'true',
              });
            }
          })
      }
    },
    //腾讯5-11位检测
    tencentset(row) {
      this.tencentshow = !this.tencentshow;
      if (row != undefined) {
        this.row_value = row;
        this.tencentstate = this.row_value.detect_qq
      }
      if (this.tencentshow == false) {
        this.$axios.post('/supplier/good/list/switch.operate', { gid: this.row_value.id, switch_type: 3, switch: this.tencentstate })
          .then((res) => {
            if (res.data.code == 20000) {
              this.$message({
                message: '设置成功!',
                type: 'success',
                center: 'true',
              });
              this.getlist(1)
            } else {
              this.$message({
                message: '设置失败!',
                type: 'warning',
                center: 'true',
              });
            }
          })
      }
    },
    //屏蔽字符设置
    pingbiset(row) {
      this.pingbishow = !this.pingbishow;
      if (row != undefined) {
        this.row_value = row;
        this.pingbistate = this.row_value.detect_character
      }
      if (this.pingbishow == false) {
        this.$axios.post('/supplier/good/list/switch.operate', { gid: this.row_value.id, switch_type: 2, switch: this.pingbistate })
          .then((res) => {
            if (res.data.code == 20000) {
              this.$message({
                message: '设置成功!',
                type: 'success',
                center: 'true',
              });
              this.getlist(1)
            } else {
              this.$message({
                message: '设置失败!',
                type: 'warning',
                center: 'true',
              });
            }
          })
      }
    },
    //下架
    todrop_up(row) {
      this.$confirm('确定是否要下架该商品?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.post("/supplier/good/rack.up.and.down", { gid: row.id, rack_up_and_down: 2 }).then(res => {
          if (res.data.code == 20000) {
            this.$message({
              type: 'success',
              message: res.data.message
            });
            this.getlist()
          } else {
            this.$message({
              type: 'warning',
              message: res.data.message
            });
          }

        })
      })

    },
    //手机号检测
    check_phone(row, state) {
      this.testqqshow = !this.testqqshow;
      if (row != undefined) {
        this.row_value = row;
        this.teststate = state;
      }
      if (this.testqqshow == false) {
        this.$axios.post('/supplier/good/list/switch.operate', { gid: this.row_value.id, switch_type: 1, switch: this.teststate }).then((res) => {
          if (res.data.code == 20000) {
            if (this.teststate == 1) {
              this.$message({
                message: '开启成功!',
                type: 'success',
                center: 'true',
              });
            } else {
              this.$message({
                message: '关闭成功!',
                type: 'success',
                center: 'true',
              });

            }
            this.getlist(1)
          } else {
            this.$message({
              message: '操作失败!',
              type: 'error',
              center: 'true',
            });
          }
        })
      }

    },
    //定时改价
    update_price() {
      if (this.row_info.change_time == '' || this.row_info.purchase_price == '') {
        this.$message({
          message: '请完整填写商品更新信息！',
          type: 'error',
          center: 'true',
        });
        return;
      }
      this.$axios.post('/supplier/good/timely.purchase.price.change', this.row_info).then((res) => {
        if (res.data.code == 20000) {
          this.$message({
            message: '定时改价设置成功',
            type: 'success',
            center: 'true',
          });
          this.updatedialog = false
          this.getlist(1)
        } else {
          this.$message({
            message: res.data.message,
            type: 'warning',
            center: 'true',
          });
          this.getlist(1)
        }
      })
    },
    //定时改价
    toupdate(row) {
      this.info_name = row.good_name
      this.row_info.gid = row.id;
      this.row_info.purchase_price = row.purchase_price;
      this.row_info.price = row.price;
      this.row_info.official_price = row.official_price;
      this.row_info.change_time = ''
      this.updatedialog = true
    },
    //定时上架
    setissuetime() {
      var start_time;
      var end_time;
      if (this.timetype == 1) {
        if (this.issuetime) {
          start_time = this.issuetime[0]
          end_time = this.issuetime[1]
        } else {
          start_time = ''
          end_time = ''
        }

      } else {
        if (this.issuetimes) {
          start_time = this.issuetimes[0]
          end_time = this.issuetimes[1]
        } else {
          start_time = ''
          end_time = ''
        }
      }
      var monday;
      var tuesday;
      var wednesday;
      var thursday;
      var friday;
      var saturday;
      var sunday;
      this.monday ? monday = 1 : monday = 2
      this.tuesday ? tuesday = 1 : tuesday = 2
      this.wednesday ? wednesday = 1 : wednesday = 2
      this.thursday ? thursday = 1 : thursday = 2
      this.friday ? friday = 1 : friday = 2
      this.saturday ? saturday = 1 : saturday = 2
      this.sunday ? sunday = 1 : sunday = 2
      if (monday == 2 && tuesday == 2 && wednesday == 2 && thursday == 2 && friday == 2 && saturday == 2 && sunday == 2) {
        this.$message({
          message: '请选择定时周期',
          type: 'warning'
        })
        return false;
      }
      if (start_time == '') {
        this.$message({
          message: '请选择时间',
          type: 'warning'
        })
        return false;
      }
      let params = {
        gid: this.row_value.id, type: this.timetype, state: this.issuetimeswitch, start_time: start_time, end_time: end_time, monday: monday,
        tuesday: tuesday, thursday: thursday, wednesday: wednesday, friday: friday, sunday: sunday, saturday: saturday
      }

      this.$axios.post("/supplier/good/timed.up.and.down", params).then((res) => {
        if (res.data.code == 20000) {
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          this.setissuedialog = false
        } else {
          this.$message({
            message: res.data.message,
            type: 'warning'
          })
        }
      })
    },
    sjtimes() {
      this.timetype = 1
      this.whtime = true
      if (this.issuetime == null) {
        this.whtime = false
      }
    },
    whtimes() {
      this.timetype = 2
      this.sjtime = true
      if (this.issuetimes == null) {
        this.sjtime = false
      }
    },
    //定时上架
    setautoissue(row) {
      this.row_value = row
      this.$axios.post("/supplier/good/get.timed.up.and.down", { gid: row.id }).then((res) => {
        if (res.data.code == 20000) {
          let data = res.data.data
          data.monday == 2 ? this.monday = false : this.monday = true
          data.tuesday == 2 ? this.tuesday = false : this.tuesday = true
          data.wednesday == 2 ? this.wednesday = false : this.wednesday = true
          data.thursday == 2 ? this.thursday = false : this.thursday = true
          data.friday == 2 ? this.friday = false : this.friday = true
          data.saturday == 2 ? this.saturday = false : this.saturday = true
          data.sunday == 2 ? this.sunday = false : this.sunday = true
          if (data.type == 1) {
            this.issuetime = [data.start_time, data.end_time]
            this.sjtime = false
            this.whtime = true
            this.timetype = 1
            this.issuetimes = ['00:00:00', '23:59:59'];
          } else {
            this.issuetime = ['00:00:00', '23:59:59'];
            this.sjtime = true
            this.whtime = false
            this.timetype = 2
            this.issuetimes = [data.start_time, data.end_time]
          }
          this.issuetimeswitch = data.state
          this.setissuedialog = true
        }


      })
    },
    //发布
    publish(row) {
      this.$confirm('您确定要发布该商品吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.post("/supplier/good/release", { gids: row.id, type: 1 }).then((res) => {
          if (res.data.code == 20000) {
            this.$message({
              type: 'success',
              message: res.data.message
            });
            this.getlist(1)
          } else {
            this.$message({
              type: 'warning',
              message: res.data.message
            });
          }
        })

      })
    },
    //取消发布
    unpublish(row) {
      this.$confirm('您确定要取消发布该商品吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.post("/supplier/good/release", { gids: row.id, type: 2 }).then((res) => {
          if (res.data.code == 20000) {
            this.$message({
              type: 'success',
              message: res.data.message
            });
            this.getlist(1)
          } else {
            this.$message({
              type: 'warning',
              message: res.data.message
            });
          }
        })

      })
    },
    //取消维护
    undefend(row) {
      this.$confirm('您确定要取消维护该商品吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.post("/supplier/good/release", { gids: row.id, type: 1 }).then((res) => {
          if (res.data.code == 20000) {
            this.$message({
              type: 'success',
              message: res.data.message
            });
            this.getlist(1)
          } else {
            this.$message({
              type: 'warning',
              message: res.data.message
            });
          }
        })

      })
    },
    //维护
    defend(row) {
      this.$confirm('您确定要维护该商品吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.post("/supplier/good/release", { gids: row.id, type: 3 }).then((res) => {
          if (res.data.code == 20000) {
            this.$message({
              message: res.data.message,
              type: 'success'
            })
            this.getlist(1)
          }
        })
      })

    },
    //编辑
    edit(row) {
      this.$router.push({ name: "Edit", params: { b: this.$route.params.b, a: this.$route.params.a, list: 2, row: row, id: this.$route.params.id, g_name: this.goodsname } })
    },
    handleSelectionChange(val) {
      this.checked = val;
    },
    //重置
    reset() {
      this.goodsname = ""
      this.goodsnum = ""
      this.goods_state = ""
      this.getlist(1)
    },
    homepage(a) {
      if (a == 1) {
        this.$router.push({ path: "/homes/goods_order" })
      } else {
        this.$router.push({ name: "Breadcrumb", params: { b: this.$route.params.c, a: this.$route.params.b, id: this.$route.params.pid } })

      }
    },
    getlist(page) {
      if (page == 1) {
        this.currentPage = 1
      }

      this.$axios.post("/supplier/good/list/page", { page: this.currentPage, limit: this.pagesize, type: 1, brand_id: this.$route.params.id, good_name: this.goodsname, coding: this.goodsnum, is_release: this.goods_state })
        .then((res) => {
          if (res.data.code == 20000) {
            this.tableData = res.data.data.rows
            this.total = res.data.data.total
          } else {
            this.tableData = res.data.data.rows
            this.total = res.data.data.total
          }
        })
    }
  },

}
</script>

<style lang="less" scoped>
.title {
  height: 40px;
  line-height: 40px;
  padding-left: 20px;
  border-bottom: 1px solid black;
  text-align: left;

  .head {
    font-size: 20px
  }

  span {
    font-size: 16px
  }
}

.agent_search {
  width: 99%;
  height: 100px;
  border: 1px solid #ccc;
  margin-top: 10px;
  float: left;
  text-align: left;
  margin-left: 10px;
}

.lab {
  font-size: 16px;
  margin: 10px 10px 10px 20px;
  display: inline-block
}

.goods_search {
  width: 220px;
  height: 25px;
  border-color: initial;
  margin-left: 3px
}

.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
}

.el-icon-arrow-down {
  font-size: 12px;
}

.timelist /deep/ .el-checkbox__input {
  display: none;
}

.timelist /deep/ .el-checkbox {
  margin-right: 8px;
}

.timelist /deep/ .el-checkbox-group {
  width: 630px;
  display: inline-block;
}
</style>